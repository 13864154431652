import revive_payload_client_CAjDcBqKW8 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xmr76lw77h5q3ifk4s7exenzgy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_D33gqlec9t from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xmr76lw77h5q3ifk4s7exenzgy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5BoGoVYjGC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xmr76lw77h5q3ifk4s7exenzgy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_bioD53vcqH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xmr76lw77h5q3ifk4s7exenzgy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_HSF4yn5o8q from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xmr76lw77h5q3ifk4s7exenzgy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_XhSGEkwnfi from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_yDKromoU8d from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xmr76lw77h5q3ifk4s7exenzgy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_X5yustKNTb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xmr76lw77h5q3ifk4s7exenzgy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cleave_HD9soKWDBf from "/opt/build/repo/plugins/cleave.ts";
import date_picker_client_l6L1nahZSs from "/opt/build/repo/plugins/date-picker.client.ts";
import axios_QMFgzss1s4 from "/opt/build/repo/plugins/axios.ts";
import bugsnag_client_TtQ636hzGt from "/opt/build/repo/plugins/bugsnag.client.ts";
import click_outside_D2NiLGp7dJ from "/opt/build/repo/plugins/click-outside.ts";
import error_handler_client_sQhJOBiqXf from "/opt/build/repo/plugins/error-handler.client.ts";
import eventBus_P6CbRuQa3W from "/opt/build/repo/plugins/eventBus.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_CAjDcBqKW8,
  unhead_D33gqlec9t,
  router_5BoGoVYjGC,
  payload_client_bioD53vcqH,
  check_outdated_build_client_HSF4yn5o8q,
  plugin_vue3_XhSGEkwnfi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yDKromoU8d,
  chunk_reload_client_X5yustKNTb,
  cleave_HD9soKWDBf,
  date_picker_client_l6L1nahZSs,
  axios_QMFgzss1s4,
  bugsnag_client_TtQ636hzGt,
  click_outside_D2NiLGp7dJ,
  error_handler_client_sQhJOBiqXf,
  eventBus_P6CbRuQa3W,
  vue_query_wrmMkNpEpe
]