import type { GetMeResponseV2 } from '~/types/apiResponse/me.response'

export function initPendo(
  personId: string,
  org: GetMeResponseV2['organisations'][number],
  profile: GetMeResponseV2,
) {
  pendo.initialize({
    visitor: {
      id: personId,
      email: profile.work_email_address,
      full_name: `${profile.given_name} ${profile.family_name}`,
    },
    account: {
      id: org.trading_name || org.registered_name || org.id,
    },
  })
}

export function setIdentityOnClarity(profile: GetMeResponseV2) {
  const { family_name, given_name, work_email_address } = profile

  ;(window as any).clarity(
    'identify',
    work_email_address,
    null,
    null,
    `${given_name} ${family_name} - ${work_email_address}`,
  )
}

export function setTagsOnClarity(
  profile: GetMeResponseV2,
  organisation: (typeof profile)['organisations'][number],
) {
  if (!organisation) return

  const clarity = (window as any).clarity

  const { family_name, given_name, work_email_address } = profile
  const { trading_name, id } = organisation

  if (given_name) clarity('set', 'first name', given_name)
  if (family_name) clarity('set', 'last name', family_name)
  if (work_email_address) clarity('set', 'email', work_email_address)
  if (id) clarity('set', 'company id', id)
  if (trading_name) clarity('set', 'company name', trading_name)
}
